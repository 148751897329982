@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aquarium";
  src: local("AQUARIUM"),
    url("./fonts/AQUARIUM-DISPLAY.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #f5cc00;
}

::-webkit-scrollbar-thumb:hover {
  background: #eab308;
}

.loader {
  width: 200px;
  height: 200px;
  border: 20px solid #fff;
  border-bottom-color: #f5cc00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: "popins", sans-serif;
}

a.active {
  color: white;
}

.swiper {
  padding: 35px 0 !important;
}

.swiper-wrapper {
  align-items: center;
}

.slick-dots li {
  width: 80px !important;
}

@media(max-width:639px) {
  .slick-dots {
    display: none !important
  }
}

.fetured-project__slider-item {
  @apply rounded-xl bg-white overflow-hidden items-center;
  display: flex !important;
}